import * as z from 'zod';

import { generateDefaultPassengerData } from '@/features/flights/components/checkout/flight-checkout-utils';
import {
  calculateAgeBasedOnDepartureTime,
  isDateNotBeforeToday,
} from './flights-validation-schema-utils';
import { validatePhoneNumber } from './phone-number-validate-utils';
import { generateNameSchema } from './travel-checkout-form-schema-utils';

export const passengerTypeSchema = z.enum(['adult', 'child', 'infant']);
export type PassengerType = z.infer<typeof passengerTypeSchema>;
export const formAncillarySchema = z
  .object({
    name: z.string(),
    value: z.string().optional().nullable(),
    required: z.boolean(),
  })
  .refine(
    (context) => {
      const { value, required } = context;
      if (!required) {
        return true;
      }
      if (value) {
        return value.trim() !== '';
      }
      return false;
    },
    {
      message: `This field is required`,
      path: ['value'],
    },
  );

export type FormAncillarySchema = z.infer<typeof formAncillarySchema>;

export const ancillariesSchema = z
  .object({
    id: z.string().optional(),
    baggages: z.any().optional(),
    meals: z.any().optional(),
    seat: z.any().optional(),
    lounge: z.any().optional(),
    customParams: z.record(z.string().min(1)).optional(),
  })
  .optional();

export type AncillariesSchema = z.infer<typeof ancillariesSchema>;

export const passengerInformationFormSchema = z
  .object({
    firstName: generateNameSchema('Given name(s)'),
    lastName: generateNameSchema('Surname'),
    salutation: z.string().min(1, 'Title is required'),
    type: passengerTypeSchema,
    dateOfBirth: z.string().min(1, 'Date of birth is required'),
    passportRequired: z.boolean().optional(),
    passportNumber: z.string().optional(),
    passportCountry: z
      .string()
      .nullish()
      .refine(
        (context) => {
          const passportCountry = context;
          if (!passportCountry) {
            return false;
          }
          return true;
        },
        {
          message: 'Nationality is required',
          path: ['passportCountry'],
        },
      ),
    passportExpiryDate: z.string().optional(),
    passengerAncillaries: ancillariesSchema,
  })
  .refine(
    (context) => {
      const { dateOfBirth } = context;
      const ageInYears = calculateAgeBasedOnDepartureTime(dateOfBirth);
      return ageInYears > 0;
    },
    {
      message:
        'Your date of birth cannot be in the future. Please enter a valid date.',
      path: ['dateOfBirth'],
    },
  )
  .refine(
    (context) => {
      const { dateOfBirth, type } = context;
      if (type === 'adult') {
        const ageInYears = calculateAgeBasedOnDepartureTime(dateOfBirth);
        return ageInYears >= 12;
      }
      return true;
    },
    {
      message:
        'To proceed you must be at least 12 years old. Please enter a valid date.',
      path: ['dateOfBirth'],
    },
  )
  .refine(
    (context) => {
      const { dateOfBirth, type } = context;
      if (type === 'child') {
        const ageInYears = calculateAgeBasedOnDepartureTime(dateOfBirth);
        return ageInYears > 2 && ageInYears < 12;
      }
      return true;
    },
    {
      message:
        'To proceed you must be between 2 to 11 years old. Please enter a valid date.',
      path: ['dateOfBirth'],
    },
  )
  .refine(
    (context) => {
      const { dateOfBirth, type } = context;
      if (type === 'infant') {
        const ageInYears = calculateAgeBasedOnDepartureTime(dateOfBirth);
        return ageInYears <= 2;
      }
      return true;
    },
    {
      message:
        'To proceed you must be below 2 years old. Please enter a valid date.',
      path: ['dateOfBirth'],
    },
  )
  .refine(
    (context) => {
      const { passportRequired, passportNumber } = context;
      if (!passportRequired) {
        return true;
      }
      if (passportNumber) {
        return passportNumber.trim() !== '';
      }
      return false;
    },
    {
      message: 'Passport number is required',
      path: ['passportNumber'],
    },
  )
  .refine(
    (context) => {
      const { passportRequired, passportNumber } = context;
      if (passportRequired && passportNumber) {
        return /^[a-zA-Z0-9]+$/.test(passportNumber);
      }
      return true;
    },
    {
      message:
        'Special characters are not allowed in this field. Please remove any special characters.',
      path: ['passportNumber'],
    },
  )
  .refine(
    (context) => {
      const { passportRequired, passportExpiryDate } = context;
      if (passportRequired) {
        return passportExpiryDate ? passportExpiryDate.length > 0 : false;
      }
      return true;
    },
    {
      message: 'Passport expiry is required',
      path: ['passportExpiryDate'],
    },
  )
  .refine(
    (context) => {
      const { passportRequired, passportExpiryDate } = context;
      if (passportRequired) {
        return passportExpiryDate
          ? isDateNotBeforeToday(passportExpiryDate)
          : false;
      }
      return true;
    },
    {
      message:
        'The date you entered has already passed. Please provide a valid future date for your passport expiry.',
      path: ['passportExpiryDate'],
    },
  );

export type PassengerInformationForm = z.infer<
  typeof passengerInformationFormSchema
>;

export const contactCheckoutFormSchema = z.object({
  firstName: generateNameSchema('First name'),
  lastName: generateNameSchema('Last name'),
  email: z
    .string()
    .min(1, 'Email is required')
    .email('Please enter a valid email address')
    .max(255, `Maximum length of email is ${255} characters`)
    .refine((value) => value.trim() !== '', {
      message: 'Email is required',
    })
    .refine((value) => !/\s/.test(value), {
      message: 'Email cannot contain white spaces',
    }),
  countryCode: z.string().optional(),
  phoneNumber: z
    .string()
    .min(1, 'Phone number is required')
    .refine((value) => value.trim() !== '', {
      message: 'Phone number is required',
    })
    .refine((value) => /^\(\+\d+\)/.test(value), {
      message: 'Country code is required',
    })
    .refine(
      (value) => {
        return validatePhoneNumber(value);
      },
      {
        message: 'Phone number is not valid',
      },
    ),
});

export type ContactCheckoutForm = z.infer<typeof contactCheckoutFormSchema>;

export enum FormStepEnum {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}

export const requiredAncillarySchema = z.object({
  name: z.string(),
  value: z.string(),
});

export const flightCheckoutFormSchema = z.object({
  primaryPassenger: passengerInformationFormSchema,
  secondaryPassengers: z.array(passengerInformationFormSchema).default([]),
  contactInformation: contactCheckoutFormSchema,
  ancillaries: ancillariesSchema, // for perPassenger -> false
});

export type FlightCheckoutFormSchema = z.infer<typeof flightCheckoutFormSchema>;
export type FlightConfirmationSchema = FlightCheckoutFormSchema;
export type SecondaryPassengersForm =
  FlightCheckoutFormSchema['secondaryPassengers'];

export const emptyPassengerInformationForm =
  generateDefaultPassengerData('adult');

export const emptyContactInformationForm: ContactCheckoutForm = {
  countryCode: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
};
