'use client';

import {
  cloneElement,
  forwardRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactElement,
  useRef,
} from 'react';
import { Input as ReactAriaInput } from 'react-aria-components';

import { Icon } from '@/components/icon';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { WontFix } from 'global';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {}

export const Input = forwardRef<
  HTMLInputElement,
  InputProps & {
    isError?: boolean;
    icon?: ReactElement | null;
    containerClassName?: string;
    clearInput?: boolean;
  }
>(
  (
    {
      clearInput = true,
      className,
      value,
      icon,
      containerClassName,
      onChange,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { isError, ...restProps } = props;

    const divRef = useRef<null | HTMLDivElement>(null);
    const isEmpty = value === undefined || value.toString().length === 0;

    const clearIcon = (
      <button
        type="button"
        className="focus-link absolute bottom-0 right-4 top-0 my-auto inline-flex h-4 w-4 items-center justify-center"
        aria-label={t('Clear input')}
        onClick={() => {
          const inputNode = divRef.current?.querySelector('input')!;

          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          onChange?.({ target: { value: '' } } as WontFix);
          inputNode.value = '';
          inputNode.dispatchEvent(new Event('input', { bubbles: true }));
          inputNode.dispatchEvent(new Event('change', { bubbles: true }));
          inputNode.focus();
        }}
      >
        <Icon name="circle-xmark" className="text-neutral-600" />
      </button>
    );

    const propsIcon =
      icon &&
      cloneElement(
        icon,
        {
          'aria-hidden': 'true',
          ...icon.props,
          ...{
            className: cn(
              'absolute bottom-0 right-4 top-0 my-auto',
              icon.props.className,
            ),
          },
        },
        icon.props.children,
      );

    return (
      <div className={cn('relative', containerClassName)} ref={divRef}>
        <ReactAriaInput
          className={cn(
            'text-md min-h-input-field w-full rounded border border-neutral-400 bg-white px-4 py-2',
            'placeholder:text-neutral-500',
            'hover:cursor-pointer hover:border-black',
            'focus-link focus:border-black focus:outline focus:outline-2',
            'without-ring focus:ring-0',
            'disabled:bg-neutral-200 disabled:text-neutral-400 disabled:placeholder:text-neutral-400 disabled:hover:cursor-not-allowed disabled:hover:border-neutral-400',
            isError ? 'border-error' : '',
            className,
          )}
          value={value}
          onChange={onChange}
          ref={ref}
          aria-invalid={isError ? 'true' : 'false'}
          {...restProps}
        />
        {clearInput ? icon && isEmpty && propsIcon : icon && propsIcon}
        {!isEmpty && clearInput && clearIcon}
      </div>
    );
  },
);

Input.displayName = 'Input';

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <label
        className={cn('text-md text-neutral-600', className)}
        ref={ref}
        {...props}
      >
        {children}
      </label>
    );
  },
);
Label.displayName = 'Label';
