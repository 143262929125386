import {
  ancillariesSchema,
  passengerInformationFormSchema,
} from '@/features/checkout/forms/flights-validation-schema';

import { z } from 'zod';

import { typedObjectKeys } from '@/utils/type-utils';
import { carSchema } from './cars/car.schema';
import { flightBookingTransactionResponseSchema } from './flights/booking-transaction/booking-transaction.schema';
import { flightSegmentSchema } from './flights/prices/prices.schema';
import { hotelBookingTransactionResponseSchema } from './hotel/booking-transaction/booking-transaction.schema';
import { cancellationPolicyContentSchema } from './travel/cancellation-policy.schema';
import { priceBreakdownSchema } from './travel/price-breakdown.schema';
import { travelTierSchema } from './travel/tier.schema';

const cartItemSchema = z.object({
  cashAmount: z.number(),
  pointsAmount: z.number(),
  productType: z.enum([
    'physical_gift_card',
    'gift_card',
    'points_transfer',
    'crypto',
    'cashback',
    'sustainability',
    'travel_booking',
    'voucher',
  ]),
  disableShoppingCart: z.boolean().optional(),
  orderDetails: z.object({
    loyaltyProgramId: z.string(),
    transferAmount: z.number(),
    capabilityId: z.string().optional(),
  }),
});

const giftCardCartItemSchema = cartItemSchema.extend({
  supplierId: z.string(),
  denominationId: z.string(),
  quantity: z.number(),
});

export const digitalGiftCardCartItemSchema = giftCardCartItemSchema.extend({
  // zod can't do discriminated unions with and enums
  // https://github.com/colinhacks/zod/issues/1444
  productType: z.literal('gift_card'),
  orderDetails: z.object({
    displayValue: z.number(),
    productName: z.string(),
    pointsPercentage: z.number(),
    originalCardId: z.string().optional(),
    recipient: z.union([
      z.object({
        message: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        email: z.string(),
      }),
      z.object({}),
    ]),
  }),
});

export type DigitalGiftCardCartItem = z.infer<
  typeof digitalGiftCardCartItemSchema
>;

const pointsTransferCartItemSchema = cartItemSchema.extend({
  // zod can't do discriminated unions with and enums
  // https://github.com/colinhacks/zod/issues/1444
  productType: z.literal('points_transfer'),
  orderDetails: z.object({
    loyaltyProgramId: z.string(),
    transferAmount: z.number(),
    number: z.number().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    capabilityId: z.string().optional(),
  }),
});

export type PointsTransferCartItem = z.infer<
  typeof pointsTransferCartItemSchema
>;

const sustainabilityCartItemSchema = cartItemSchema.extend({
  productType: z.literal('sustainability'),
});

const uberCreditCartItemSchema = cartItemSchema.extend({
  productType: z.literal('voucher'),
});

const cashbackCartItemSchema = cartItemSchema.extend({
  productType: z.enum(['cash_redemption']),
  orderDetails: z.object({
    loyaltyProgramId: z.string(),
    cashAmount: z.number(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    capabilityId: z.string().optional(),
  }),
});

const cryptoCartItemSchema = cartItemSchema.extend({
  productType: z.literal('crypto'),
});

export const shoppingCartItemRequestSchema = z.object({
  cartItem: z.discriminatedUnion('productType', [
    pointsTransferCartItemSchema,
    digitalGiftCardCartItemSchema,
    sustainabilityCartItemSchema,
    cryptoCartItemSchema,
    cashbackCartItemSchema,
    uberCreditCartItemSchema,
  ]),
});

export const shoppingCartItemSchema = z.object({
  productType: z.string(),
  supplierId: z.string(),
  quantity: z.number(),
  orderDetails: z.object({
    recipient: z.object({}),
    displayValue: z.number(),
    productName: z.string(),
    pointsPercentage: z.number(),
  }),
  displayDetails: z.object({
    description: z.string().nullable(),
    productImage: z.string(),
    available: z.boolean(),
    displayValue: z.number(),
  }),
  pointsAmount: z.number(),
  pointsDiscount: z.number(),
  pointsPaid: z.number(),
  cashAmount: z.number(),
  cashDiscount: z.number(),
  cashPaid: z.number(),
  id: z.string(),
  type: z.string(),
});

export type ShoppingCartItemRequest = z.infer<
  typeof shoppingCartItemRequestSchema
>;

export type ShoppingCartItem = z.infer<typeof shoppingCartItemSchema>;

export const recipientSchema = z.object({
  salutation: z.string().optional(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
});

export type Recipient = z.infer<typeof recipientSchema>;

export const paymentMethodSchema = z.object({
  id: z.string(),
  saveCard: z.boolean(),
  returnUrl: z.string().optional(),
});

export const flightOrderDetailSchema = z.object({
  travelType: z.literal('flights'),
  bookingKey: z.string(),
  tierId: z.number(),
  flightType: z.enum(['ow', 'rt']),
  cabinClass: z.enum(['Y', 'C', 'F', 'S']),
  allSegments: z.array(flightSegmentSchema).nonempty(),
  originSegments: z.array(flightSegmentSchema),
  returnSegments: z.array(flightSegmentSchema).optional(),
  passengers: z.array(passengerInformationFormSchema).optional(),
  ancillaries: ancillariesSchema,
  recipient: recipientSchema.optional(),
  paymentMethod: paymentMethodSchema.optional(),
  freeformPoints: z.number().optional(),
  freeformPointsEnabled: z.boolean().optional(),
});

export type FlightOrderDetailSchema = z.infer<typeof flightOrderDetailSchema>;

export const createFlightShoppingCartItemSchema = z.object({
  productType: z.literal('travel_booking'),
  cashAmount: z.number(),
  pointsAmount: z.number(),
  quantity: z.number().optional(),
  orderDetails: flightOrderDetailSchema,
});

export type CreateFlightShoppingCartItem = z.infer<
  typeof createFlightShoppingCartItemSchema
>;

export const displayDetails = z.object({
  travelType: z.literal('flights'),
  bookingKey: z.string(),
  available: z.boolean(),
  bookingTransactionData: flightBookingTransactionResponseSchema.optional(),
  tiers: z.array(travelTierSchema),
});

export type FlightCheckoutDisplayDetails = z.infer<typeof displayDetails>;

export const updateFlightShoppingCartItemSchema = z.object({
  cashAmount: z.number().optional(),
  pointsAmount: z.number().optional(),
  orderDetails: flightOrderDetailSchema.optional(),
  displayDetails: displayDetails.optional(),
  priceChangeAccepted: z.boolean().optional(),
  ancillaries: ancillariesSchema,
});

export type UpdateFlightShoppingCartItem = z.infer<
  typeof updateFlightShoppingCartItemSchema
>;

// hotel Schema
export const createHotelOrderDetailSchema = z.object({
  tierId: z.number(),
  travelType: z.literal('hotels'),
  bookingKey: z.string(),
  cancellationPolicy: cancellationPolicyContentSchema,
  hotel: z.object({
    hotelId: z.string(),
    hotelName: z.string(),
    destinationId: z.string(),
    roomDescription: z.string(),
    checkin: z.string(),
    checkout: z.string(),
    roomCount: z.string(),
    adultCount: z.string(),
    childCount: z.string(),
    hotelAddress: z.string(),
    guests: z.string(),
  }),
  originalMetadata: z
    .object({
      name: z.null(),
      city: z.string(),
      state: z.null(),
      country: z.string(),
    })
    .optional(),
  freeformPointsEnabled: z.boolean().optional(),
});

export const updateHotelOrderDetailSchema = createHotelOrderDetailSchema.extend(
  {
    paymentMethod: paymentMethodSchema.optional(),

    guest: recipientSchema.omit({ phoneNumber: true }).extend({
      phone: z.string(),
      specialRequest: z.string().nullable(),
    }),
    recipient: recipientSchema,
    freeformPoints: z.number().optional(),
  },
);

// export type HotelOrderDetail = z.infer<typeof hotelOrderDetailSchema>;

export const createHotelShoppingCartItemSchema = cartItemSchema.extend({
  productType: z.literal('travel_booking'),
  quantity: z.number().optional(),
  orderDetails: createHotelOrderDetailSchema,
});

export type CreateHotelShoppingCartItem = z.infer<
  typeof createHotelShoppingCartItemSchema
>;

export const updateHotelShoppingCartItemSchema = cartItemSchema.extend({
  productType: z.literal('travel_booking'),
  quantity: z.number().optional(),
  orderDetails: updateHotelOrderDetailSchema,
});

export type UpdateHotelShoppingCartItem = z.infer<
  typeof updateHotelShoppingCartItemSchema
>;

export const breakfastOptions = {
  hotel_detail_breakfast_for_1_included: 'Breakfast included for 1',
  hotel_detail_breakfast_for_2_included: 'Breakfast included for 2',
  hotel_detail_breakfast_included: 'Breakfast included',
  hotel_detail_breakfast_included_adult_only:
    'Breakfast included (adult guests only) ',
  hotel_detail_dinner_included: 'Dinner included',
  hotel_detail_full_board: 'Full board',
  hotel_detail_half_board: 'Half board',
  hotel_detail_lunch_included: 'Lunch included',
  hotel_detail_room_only: 'Room only',
};

const breakfastOptionsKeys = typedObjectKeys(breakfastOptions);

// https://github.com/colinhacks/zod/discussions/839#discussioncomment-6488540
// we need this to support enums from objects
export const breakfastOptionsSchema = z.enum([
  breakfastOptionsKeys[0],
  ...breakfastOptionsKeys.slice(1),
]);

const importantInformationSchema = z.object({
  specialCheckInInstructions: z.string().nullable(),
  checkInInstructions: z.string().nullable(),
  knowBeforeYouGo: z.string().nullable(),
  feesOptional: z.string().nullable(),
  feesMandatory: z.string().nullable(),
});

export type HotelImportantInformation = z.infer<
  typeof importantInformationSchema
>;

export const hotelShoppingCartItemSchema = z.object({
  id: z.string(),
  type: z.string(),
  productType: z.literal('travel_booking'),
  quantity: z.number(),
  pointsAmount: z.number(),
  pointsDiscount: z.number(),
  pointsPaid: z.number(),
  cashAmount: z.number(),
  cashDiscount: z.number(),
  cashPaid: z.number(),
  orderDetails: updateHotelOrderDetailSchema,
  displayDetails: z.object({
    travelType: z.literal('hotels'),
    bookingKey: z.string(),
    available: z.boolean(),
    freeCancellation: z.boolean(),
    tiers: z.array(travelTierSchema),
    breakfastInfo: breakfastOptionsSchema,
    importantInformation: importantInformationSchema.optional(),
    bookingTransactionData: hotelBookingTransactionResponseSchema.optional(),
    heroImageUrl: z.string(),
    refundable: z.string(),
    restrictive: z.boolean(),
    penalties: z.array(
      z.object({ percentage: z.string(), to: z.string(), from: z.string() }),
    ),
    priceBreakdown: priceBreakdownSchema,
  }),
});

export type HotelShoppingCartItem = z.infer<typeof hotelShoppingCartItemSchema>;

// Car schemas

// hotel and car share the same cancellation policy schema
export type CarCancellationPolicy = z.infer<
  typeof cancellationPolicyContentSchema
>;

export const carDriverSchema = recipientSchema
  .omit({ phoneNumber: true })
  .extend({
    driverCountry: z.string(),
    age: z.number(),
    phone: z.string(),
    flightNumber: z.string().optional(),
  });

export type Driver = z.infer<typeof carDriverSchema>;

export const carSearchParamsSchema = z.object({
  pickupLocation: z.string(),
  pickupTime: z.string(),
  pickupLocationName: z.string(),
  returnLocation: z.string(),
  returnTime: z.string(),
  returnLocationName: z.string(),
});

export type CarSearchParams = z.infer<typeof carSearchParamsSchema>;

export const createCarOrderDetailSchema = z.object({
  tierId: z.number(),
  travelType: z.literal('cars'),
  bookingKey: z.string(),
  cancellationPolicy: cancellationPolicyContentSchema,
  driver: carDriverSchema.pick({ age: true, driverCountry: true }),
  search: carSearchParamsSchema,
  freeformPointsEnabled: z.boolean().optional(),
});

export const updateCarOrderDetailSchema = createCarOrderDetailSchema.extend({
  paymentMethod: paymentMethodSchema.optional(),
  driver: carDriverSchema,
  recipient: recipientSchema,
  freeformPoints: z.number().optional(),
});

export const createCarShoppingCartItemSchema = cartItemSchema.extend({
  productType: z.literal('travel_booking'),
  quantity: z.number().optional(),
  orderDetails: createCarOrderDetailSchema,
});

export type CreateCarShoppingCartItem = z.infer<
  typeof createCarShoppingCartItemSchema
>;

export const updateCarShoppingCartItemSchema = cartItemSchema.extend({
  productType: z.literal('travel_booking'),
  quantity: z.number().optional(),
  orderDetails: updateCarOrderDetailSchema,
});

export type UpdateCarShoppingCartItem = z.infer<
  typeof updateCarShoppingCartItemSchema
>;

export const carFeeSchema = z.object({
  amount: z.string(),
  currencyCode: z.string(),
  description: z.string(),
  purpose: z.string(),
});

export const carShoppingCartItemSchema = z.object({
  id: z.string(),
  type: z.string(),
  productType: z.literal('travel_booking'),
  quantity: z.number(),
  pointsAmount: z.number(),
  pointsDiscount: z.number(),
  pointsPaid: z.number(),
  cashAmount: z.number(),
  cashDiscount: z.number(),
  cashPaid: z.number(),
  orderDetails: updateCarOrderDetailSchema,
  displayDetails: z.object({
    travelType: z.literal('cars'),
    car: carSchema.pick({
      aircon: true,
      baggage: true,
      category: true,
      charges: true,
      imageUrl: true,
      name: true,
      passengers: true,
      pricedCoverages: true,
      transmissionType: true,
    }),
    tiers: z.array(travelTierSchema),
    bookingKey: z.string(),
    location: z.object({
      supplierAddress: z.string(),
      supplierLng: z.string(),
      supplierLat: z.string(),
      dropoffSupplierAddress: z.string(),
      dropoffSupplierLng: z.string(),
      dropoffSupplierLat: z.string(),
    }),
    fees: z.array(carFeeSchema).nullish(),
  }),
});

export type CarShoppingCartItem = z.infer<typeof carShoppingCartItemSchema>;
