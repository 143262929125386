export function isTruthyPredicate<SomeType>(
  item: SomeType | boolean | null | undefined,
): item is SomeType {
  return !!item;
}

/**
 * @deprecated Use `@/app/(types)/page-props` instead.
 */
export type PageProps = {
  params: Record<string, string>;
  searchParams?: Record<string, string>;
};

export function typedObjectKeys<T extends object>(obj: T) {
  return Object.keys(obj) as Array<keyof T>;
}
