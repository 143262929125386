import { travelTierSchema } from '@/schema/travel/tier.schema';
import { z } from 'zod';

export const flightTypeEnum = z.enum(['rt', 'ow']);
export const flightSegmentTypeEnum = z.enum(['origin', 'return']);

export const cabinClasses = {
  Economy: 'Y',
  Business: 'C',
  FirstClass: 'F',
  PremiumEconomy: 'S',
} as const;

export const flightCabinEnum = z.enum([
  cabinClasses.Business,
  cabinClasses.Economy,
  cabinClasses.FirstClass,
  cabinClasses.PremiumEconomy,
]);

export const flightsSearchSchema = z.object({
  toAirport: z.string(),
  returnDate: z.string().nullable(),
  passengers: z.string(),
  fromAirport: z.string(),
  flightType: flightTypeEnum,
  departureDate: z.string(),
  cabin: flightCabinEnum,
  currency: z.string(),
});

export const flightSegmentSchema = z.object({
  aircraftType: z.string().nullable(),
  aircraftTypeName: z.string().nullable(),
  arrivalTime: z.string(),
  baggageAllowance: z.number(),
  cabin: flightCabinEnum,
  departureTime: z.string(),
  duration: z.number(),
  fareBrandName: z.string(),
  fareClassDescription: z.array(z.string()),
  fareReference: z.string(),
  fromAirport: z.string(),
  fromCity: z.string().optional(),
  marketingAirlineCode: z.string(),
  marketingAirlineFlightNo: z.string(),
  mealCode: z.string().nullable(),
  operatingAirlineCode: z.string(),
  operatingAirlineFlightNo: z.string(),
  segmentType: flightSegmentTypeEnum,
  toAirport: z.string(),
  toCity: z.string().optional(),
});

export const flightTierSchema = z.object({
  tier: z.number(),
  cashPayment: z.number(),
  cashPaymentInUsd: z.number(),
  pointsPayment: z.number(),
  costToPartner: z.number(),
  costToPartnerInUsd: z.number(),
  points: z.number(),
});

export const ancillarySchema = z.object({
  ancillaryType: z.string(),
  fieldType: z.string(),
  label: z.string(),
  perPassenger: z.boolean(),
  options: z
    .array(
      z.object({
        value: z.string(),
        text: z.string(),
      }),
    )
    .optional()
    .nullable(),
  required: z.boolean(),
  supplierId: z.string(),
});

export type Ancillary = z.infer<typeof ancillarySchema>;

export const dynamicFieldSchema = z.object({
  dynamicFields: z.array(ancillarySchema).optional().nullable(),
});

export type DynamicField = z.infer<typeof dynamicFieldSchema>;

export const flightSegmentSchemaBookingTransaction = flightSegmentSchema.extend(
  {
    marketingAirlineName: z.string(),
    operatingAirlineName: z.string(),
  },
);

const flightAwardSegmentSchema = z.object({
  fromAirport: z.string(),
  toAirport: z.string(),
  cabin: flightCabinEnum,
  departureTime: z.string(),
  arrivalTime: z.string(),
  marketingAirlineCode: z.string(),
  marketingAirlineFlightNo: z.string(),
  operatingAirlineCode: z.string(),
  operatingAirlineFlightNo: z.string(),
  aircraftType: z.string(),
  aircraftTypeName: z.string(),
});

export const flightAwardItinerarySchema = z.object({
  flightPlanId: z.string(),
  searchResultId: z.string(),
  id: z.string(),
  source: z.string(),
  transferConnectIdentifier: z.string(),
  quantity: z.number().int().min(1).max(9),
  points: z.number(),
  fee: z.object({
    amount: z.number(),
    currency: z.string(),
  }),
  segments: z.array(flightAwardSegmentSchema).nonempty(),
});

export const flightAwardSearchSchema = z.object({
  completed: z.boolean(),
  errors: z.array(z.object({ message: z.string(), code: z.string() })),
  search: z.object({
    departureDate: z.string(),
    passengers: z.string(),
    fromAirport: z.string(),
    toAirport: z.string(),
    cabin: flightCabinEnum,
    offerSearchId: z.string(),
  }),
  awards: z.array(flightAwardItinerarySchema),
});

export const flightItinerarySchema = z.object({
  ancillaries: dynamicFieldSchema.optional().nullable(),
  bookingKey: z.string(),
  fareSourceCode: z.string(),
  fareType: z.string().nullable(),
  source: z.string(),
  segments: z.array(flightSegmentSchema).nonempty(),
  maxCashPayment: z.number(),
  maxPointsPayment: z.number(),
  maxCashPaymentInUsd: z.number(),
  // We might need to remove this fields cause this is based on TE's return not NN's
  cost: z.number().optional(),
  costInUsd: z.number().optional(),
  exchangeRate: z.number().optional(),
  costPerPoint: z.number().optional(),
  markup: z.number().optional(),
  clientMarginInUsd: z.number().optional(),
  ascendaMarginInUsd: z.number().optional(),
  tiers: z.array(travelTierSchema).nonempty(),
  passportRequired: z.boolean().optional(),
  flightFareId: z.string(),
  originFlightPlanId: z.string().optional(),
  returnFlightPlanId: z.string().optional(),
  awardItineraries: z.array(flightAwardItinerarySchema).optional(),
});

export const flightsPricesSchema = z.object({
  completed: z.boolean(),
  search: flightsSearchSchema,
  itineraries: z.array(flightItinerarySchema),
});

/** Flight Type
 *
 * ow: One way
 *
 * rt: Round trip
 */
export type FlightType = z.infer<typeof flightTypeEnum>;

/** Flight Cabin
 *
 * Y: Economy
 *
 * S: Premium economy
 *
 * C: Business
 *
 * F: First class
 */
export type FlightCabin = z.infer<typeof flightCabinEnum>;

export type FlightSegmentType = z.infer<typeof flightSegmentTypeEnum>;

export type FlightsSearch = z.infer<typeof flightsSearchSchema>;
export type FlightSegment = z.infer<typeof flightSegmentSchema>;
export type FlightItinerary = z.infer<typeof flightItinerarySchema>;
export type FlightsPrices = z.infer<typeof flightsPricesSchema>;

export type FlightAwardSegment = z.infer<typeof flightAwardSegmentSchema>;
export type FlightAwardItinerary = z.infer<typeof flightAwardItinerarySchema>;
export type FlightAwardSearch = z.infer<typeof flightAwardSearchSchema>;

export const flightTypeLabel: Record<FlightType, string> = {
  ow: 'One-way',
  rt: 'Round-trip',
} as const;

export const flightCabinLabel: Record<FlightCabin, string> = {
  Y: 'Economy',
  S: 'Premium economy',
  C: 'Business',
  F: 'First class',
} as const;
