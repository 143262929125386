import { travelTierSchema } from '@/schema/travel/tier.schema';
import { z } from 'zod';
import { cancellationPolicyContentSchema } from '../travel/cancellation-policy.schema';

const CarCategoryValues = [
  '12_foot_truck',
  '12_passenger_van',
  '15_passenger_van',
  '20_foot_truck',
  '24_foot_truck',
  '26_foot_truck',
  '50_passenger_coach',
  '5_seat_minivan',
  '7_seat_minivan',
  '9_seat_minivan',
  'cargo_van',
  'compact',
  'compact_elite',
  'convertible',
  'economy',
  'economy_elite',
  'estate',
  'exotic',
  'exotic_suv',
  'four_wheel_drive',
  'fullsize',
  'fullsize_elite',
  'intermediate',
  'intermediate_elite',
  'large_suv',
  'large_truck',
  'luxury',
  'luxury_elite',
  'medium_suv',
  'midsize',
  'mini',
  'minivan',
  'mini_elite',
  'moped',
  'moving_van',
  'oversize',
  'premium',
  'premium_elite',
  'regular',
  'small_medium_truck',
  'small_suv',
  'special',
  'standard',
  'standard_elite',
  'stretch',
  'subcompact',
  'suv',
  'unique',
] as const;

export type CarCategory = (typeof CarCategoryValues)[number];

const CarRatingValues = ['excellent', 'fair', 'good', 'very-good'] as const;

export type CarRating = (typeof CarRatingValues)[number];

export const pricedCoverages = z.array(
  z.object({
    type: z.string(),
    currency: z.string().nullable(),
    amount: z.string().nullable(),
  }),
);

export type CarPricedCoverages = z.infer<typeof pricedCoverages>;

export type CarPricedCoverage = z.infer<typeof pricedCoverages>[number];

export const charges = z.array(z.string());

export type CarCharges = z.infer<typeof charges>;

export const pricedEquips = z.array(
  z.object({
    type: z.string(),
    currency: z.string(),
    rate: z.string(),
    taxInclusive: z.string(),
    included: z.boolean(),
  }),
);
export type CarPricedEquips = z.infer<typeof pricedEquips>;

export const CarFuelTypes = ['diesel', 'petrol', 'unspecified'] as const;

export type CarFuelType = (typeof CarFuelTypes)[number];

export const CarTransmissions = ['Automatic', 'Manual'] as const;

export type CarTransmission = (typeof CarTransmissions)[number];

export const carSchema = z.object({
  unlimitedDistance: z.boolean(),
  transmissionType: z.enum(CarTransmissions),
  pricedEquips,
  pricedCoverages,
  passengers: z.number(),
  name: z.string(),
  imageUrl: z.string(),
  fuelType: z.enum(CarFuelTypes),
  fuelPolicy: z.string(),
  doors: z.number(),
  rawCancellationPolicy: z.object({
    amount: z.string(),
    currency: z.string(),
    timePeriod: z.string(),
    timeUnit: z.string(),
    type: z.string(),
  }),
  charges,
  cancellationPolicy: cancellationPolicyContentSchema,
  category: z.enum(CarCategoryValues),
  bookingKey: z.string(),
  baggage: z.number(),
  aircon: z.boolean(),
  dropoffSupplierAddress: z.string(),
  dropoffSupplierLng: z.string(),
  dropoffSupplierLat: z.string(),
  supplierName: z.string(),
  supplierImageUrl: z.string(),
  supplierLng: z.string(),
  supplierLat: z.string(),
  supplierAddress: z.string(),
  supplierDistance: z.number(),
  reviewsCount: z.number(),
  rating: z.string(),
  ratingCategory: z.enum(CarRatingValues),
  maxCashPayment: z.number(),
  maxPointsPayment: z.number(),
  baseCashPayment: z.number(),
  basePointsPayment: z.number(),
  cashNonFixedDiscount: z.number(),
  cashFixedDiscount: z.number(),
  pointsNonFixedDiscount: z.number(),
  pointsFixedDiscount: z.number(),
  bonusProgramType: z.string(),
  hasDiscountForCouponCode: z.boolean(),
  bonusPrograms: z.array(z.unknown()),
  tiers: z.array(travelTierSchema),
});

export const carArraySchema = z.array(carSchema);

export type Car = z.infer<typeof carSchema>;

export const carResponseSchema = z.object({
  completed: z.boolean(),
  currency: z.string(),
  cars: z.array(carSchema),
});

export const carTermsResponseSchema = z.object({
  driversAge: z.array(z.string()),
  driversLicence: z.array(z.string()),
  paymentOptions: z.array(z.string()),
  insuranceCoverage: z.array(z.string()),
  breakdownAssistance: z.array(z.string()),
  fuelPolicy: z.array(z.string()),
  mileagePolicy: z.array(z.string()),
  optionalExtras: z.array(z.string()),
  conditionsOfOptionalExtras: z.array(z.string()),
  additionalDriver: z.array(z.string()),
  travelRestrictions: z.array(z.string()),
  taxRate: z.array(z.string()),
  fees: z.array(z.string()),
  formsOfIds: z.array(z.string()),
  carTypes: z.array(z.string()),
  voucher: z.array(z.string()),
  policies: z.array(z.string()),
});

export type CarPricesSchema = z.infer<typeof carResponseSchema>;
export type CarTermsSchema = z.infer<typeof carTermsResponseSchema>;
