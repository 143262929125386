'use client';

import { buttonVariants } from '@/design-system-components/button/button-variants';
import { cn } from '@/utils/tailwind';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { forwardRef } from 'react';

interface RadioPillsProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {
  ariaLabel: string;
}

const RadioPills = forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  RadioPillsProps
>(({ className, ariaLabel, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-4', className)}
      aria-label={ariaLabel}
      {...props}
      ref={ref}
    />
  );
});
RadioPills.displayName = RadioGroupPrimitive.Root.displayName;

interface RadioPillItemProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {}

const RadioPillItem = forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioPillItemProps
>(({ className, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        buttonVariants({
          variant: 'secondary',
          size: 'radio',
        }),
        // Used to centralise text
        'flex justify-center',
        // Used as buttonVariants has font-bold by default
        'font-normal',
        className
      )}
      {...props}
    >
      {children}
    </RadioGroupPrimitive.Item>
  );
});
RadioPillItem.displayName = RadioGroupPrimitive.Item.displayName;

interface RoundedRadioPillItemProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {}

const RoundedRadioPillItem = forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RoundedRadioPillItemProps
>(({ className, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        // base style
        'rounded-full border border-neutral-400 px-5 py-2 text-neutral-900',
        // hover style
        'hover:border-black',
        // checked style
        'data-[state=checked]:bg-primary data-[state=checked]:border-primary data-[state=checked]:text-white',
        // checked and hovered style
        'data-[state=checked]:border-primary data-[state=checked]:hover:bg-white data-[state=checked]:hover:text-neutral-900',
        // focus style
        'focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-0',
        className
      )}
      {...props}
    >
      {children}
    </RadioGroupPrimitive.Item>
  );
});
RoundedRadioPillItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioPills, RadioPillItem, RoundedRadioPillItem };
