import { z } from 'zod';

export const generateNameSchema = (label = 'First name') => {
  const requiredMessage = `${label} is required`;
  return z
    .string()
    .min(1, requiredMessage)
    .refine((value) => value.trim() !== '', {
      message: requiredMessage,
    })
    .refine((value) => validateName(value), {
      message:
        'Special characters are not allowed in this field. Please remove any special characters and enter your name using standard English letters only.',
    });
};

// only accepts alphanumeric characters
// does not accept special characters
export const validateName = (value: string) => {
  return /^\s*[\dA-Za-z]+(?:[ -]*[\dA-Za-z]+)*\s*$/.test(value);
};
